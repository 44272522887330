import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import de from "./locales/de.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: en,
      },
      de: {
        translations: de,
      },
    },
    // fallbackLng: "en", // You can optionally set a fallback language
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
  const currentLanguage = i18n.language || 'en';
  localStorage.setItem('i18nextLng', currentLanguage);
export default i18n;
